.root {
  max-width: 1600px;
  padding: 0 2.5rem;
  margin: auto;
}
.goods {
  margin-block-start: 2rem;
}
.pay {
  max-width: 16em;
  margin: auto;
}

@media (max-width: 768px) {
  .pay {
    max-width: none;
  }
}
