.item {
  composes: round from '../../utils.css';
  display: flex;
  justify-content: space-between;
  gap: 3em;
  padding: 1.5em;
  border: 1px solid var(--gray-border);
  margin-block-end: 1.5em;
  cursor: pointer;
}
.item[data-disabled] {
  background: var(--gray-light);
  pointer-events: none;
}
.item[data-selected] {
  border-color: var(--theme-primary);
  outline: 1px solid var(--theme-primary);
}
.cover {
  display: flex;
  align-items: center;
  gap: 0.8em;
}
.avatar {
  composes: circle from '../../utils.css';
  width: 3em;
  aspect-ratio: 1;
  object-fit: cover;
}
.name {
  composes: line-height-fixed from '../../utils.css';
  margin-inline-end: 0.3em;
  font-weight: 600;
}
.staff {
  margin-block-start: 0.5em;
  font-size: 0.6em;
  line-height: 0;
  color: var(--gray-text-secondary);
}
.description {
  margin-inline-start: 5.43em;
  margin-block-start: 0.64em;
  font-weight: 500;
  font-size: 0.7em;
  color: var(--gray-text-secondary);
}
.actions {
  text-align: end;
}
.actions > :not(:first-child) {
  margin-block-start: 0.15em;
}
.time {
  font-weight: 600;
  font-size: 0.8em;
  white-space: nowrap;
  color: var(--gray-text-primary);
}
.stock {
  font-weight: 600;
  font-size: 0.6em;
  color: var(--gray-text-secondary);
}
.actions .button {
  margin-block-start: 0.5em;
}

@media (max-width: 768px) {
  .item {
    display: revert;
    text-align: center;
  }
  .cover {
    display: revert;
  }
  .description {
    margin-inline-start: 0;
  }
  .actions {
    text-align: inherit;
  }
}
